<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-2">
        <div class="form-group mb-3">
          <label>Date</label>
          <date-picker
            range
            v-model="filters.daterange"
            append-to-body
            lang="en"
            confirm
          ></date-picker>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>Search</label>
          <b-form-input
            v-model="search"
            v-on:keyup="searchArticle" 
            @search="resetFilters"
            type="search"
            class="form-control search-form-control"
            placeholder="Search.."
          ></b-form-input>
        </div>
      </div>
      <div class="col-lg-12">
        <TopViewers :filters="filters"></TopViewers>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import TopViewers from "../TopViewers.vue";
import DatePicker from "vue2-datepicker";
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
    TopViewers,
  },
  data() {
    return {
      filters: {
        search: "",
        daterange: null,
        viewer_id: null,
      },
      search: "",
      title: "Top 20 Viewers",
      items: [
        {
          text: "Medisage",
        },
        {
          text: "Articles",
        },
        {
          text: "Top Viewers",
          active: true,
        },
      ],
      reload: 0,
      params: {},
      loading: true,
    };
  },
  created() {
    // this.init();
  },
  methods: {
    searchArticle(event) {
      if (this.search.length > 3) {
        if (event.keyCode == 13) {
          this.filters.search = this.search;
          console.log(this.filters.search);
        }
      }
    },
    resetFilters() {
      if(!this.search){
        this.filters.search = null;
      }
    },
    init() {
      // this.loading = true;
      // this.loading = false;
    },
    setFilters(v) {
      this.filters = v;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        // this.init();
      },
    },
  },
};
</script>

<style>
.search-form-control{
  height: 35px !important;
}
</style>